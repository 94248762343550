<template>
  <div
    class="InputSelect"
    :class="{
      isValue,
      isTiles,
      isInvalid,
      isDisabled,
      isPlaceholderPersistent,
    }">

    <!-- Option 1: Tiles -->
    <template
      v-if="isTiles">
      <div
        class="InputSelect__tile"
        :class="{ isSelected: isOptionSelected(option) }"
        v-for="option in options"
        tabindex="0"
        :key="option.value"
        @keyup.enter.space="handleInput(option)"
        @click="handleInput(option)">
        <slot
          name="tile"
          :option="option"
          :is-selected="isOptionSelected(option)">
        </slot>
      </div>
    </template>

    <!-- Option 2: Dropdown -->
    <template
      v-else>
      <button
        class="Field"
        ref="field"
        type="button"
        @click.prevent="toggleDropdown">
        <span
          class="__placeholder"
          v-if="placeholder && (isPlaceholderPersistent || !isValue)"
          key="input-select-placeholder">
          {{ placeholder }}
        </span>

        {{ computedValueLabel }}

        <IconBase
          class="__icon"
          name="caret-down"
        />
      </button>

      <InputSelectDropdown
        v-show="isDropped"
        ref="dropdown"
        :model-value="modelValue"
        :options="options"
        :is-searchable="isSearchable"
        :search-placeholder="searchPlaceholder"
        @update:model-value="handleInput">
      </InputSelectDropdown>
    </template>

  </div>
</template>


<script>
import { isEmpty } from 'lodash';
import IconBase from '@/components/IconBase.vue';
import InputSelectDropdown from '@/components/InputSelectDropdown.vue';

export default {
  name: 'InputSelect',

  components: {
    IconBase,
    InputSelectDropdown,
  },

  data() {
    return {
      isDropped: false,
    };
  },

  props: {
    modelValue: {
      type: [String, Array, Boolean],
      required: false, // <- 'false' to allow 'null'
    },
    isMultiSelect: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      required: true,
    },
    isSearchable: {
      type: Boolean,
      default: false,
    },
    searchPlaceholder: {
      type: String,
      default: 'Search',
    },
    isTiles: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    isPlaceholderPersistent: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    'select',
    'update:modelValue',
  ],

  computed: {
    selectedOption() {
      if (!this.isMultiSelect) {
        return this.options.find((o) => o.value === this.modelValue);
      }

      return this.options.filter((o) => this.modelValue.some((v) => v === o.value));
    },

    computedValueLabel() {
      if (this.isMultiSelect) return `${this.selectedOption.length} selected`;
      if (this.selectedOption) return this.selectedOption.label;
      return null;
    },

    isValue() {
      return (typeof this.modelValue === 'boolean')
        ? true
        : this.modelValue && this.modelValue.length;
    },
  },

  beforeUnmount() {
    if (!this.isTiles) this.closeDropdown();
  },

  methods: {
    handleInput(option) {
      if (this.isDisabled) return;

      // Case 1: Single Select
      if (!this.isMultiSelect) {
        this.$emit('update:modelValue', option.value);
        this.$emit('select', option);
        if (!this.isTiles) this.closeDropdown();
        return;
      }

      // Case 2: Multi-Select with no value (i.e. null)
      if (!this.modelValue) {
        this.$emit('update:modelValue', [option.value]);
        this.$emit('select', option);
        return;
      }

      // Case 3: Multi-Select with existing value (i.e. [...values])
      const selectedFiltered = this.modelValue.filter((sv) => sv !== option.value);
      this.$emit('update:modelValue', (selectedFiltered.length < this.modelValue.length)
        ? selectedFiltered
        : [...this.modelValue, option.value]);
      this.$emit('select', option);
    },

    isOptionSelected(option) {
      const modelValueType = typeof this.modelValue;

      if (modelValueType === 'string') {
        if (!this.modelValue) return !option.value;
        return this.modelValue === option.value;
      }

      if (modelValueType === 'object') {
        if (isEmpty(this.modelValue)) return false;
        return this.modelValue.some((selectedValue) => selectedValue === option.value);
      }

      if (modelValueType === 'boolean') {
        return this.modelValue === option.value;
      }

      return false;
    },

    toggleDropdown() {
      this[`${this.isDropped ? 'close' : 'open'}Dropdown`]();
    },

    handleWindowKeyup(e) {
      if (e.key === 'Escape') {
        e.preventDefault();
        this.closeDropdown();
      }
    },

    handleClickOutside(e) {
      const isInElement = e.target === this.$refs.dropdown.$el
        || this.$refs.dropdown.$el.contains(e.target);

      if (!isInElement) this.closeDropdown(false);
    },

    openDropdown() {
      this.isDropped = true;

      setTimeout(() => {
        window.addEventListener('keyup', this.handleWindowKeyup);
        window.addEventListener('click', this.handleClickOutside);

        if (this.isSearchable) return this.$refs.dropdown.focusSearch();

        return this.$refs.dropdown.focusOption(0);
      }, 200);
    },

    closeDropdown(refocusField = true) {
      this.isDropped = false;
      this.$refs.dropdown.reset();
      window.removeEventListener('keyup', this.handleWindowKeyup);
      window.removeEventListener('click', this.handleClickOutside);
      if (refocusField) this.$refs.field.focus();
    },
  },
};
</script>


<style lang="scss" scoped>
@import '@/styles/_variables';

.InputSelect {
  position: relative;
  text-align: left;
}

// Field
.Field {
  display: block;
  position: relative;
  width: 100%;
  height: 44px;
  padding-left: 12px;
  text-align: left;
  border: 1px solid $border-color;
  border-radius: $radius;
  &:focus {
    border: 1px solid $dark;
  }
  .__icon {
    position: absolute;
    top: 8px;
    right: 4px;
  }
}


// Field Placeholder
.__placeholder {
  position: absolute;
  top: 9px;
  left: 12px;
  color: $mute;
  pointer-events: none;
  user-select: none;
  transform-origin: top left;
  will-change: transform;
  transition: transform 0.1s ease 0s;
}
.isPlaceholderPersistent.isValue {
  & > .Field {
    padding-top: 6px;
  }

  & > .Field > .__placeholder {
    top: 10px;
    transform: translate(0, -6px) scale(0.5);
  }
}


// isTiles
.InputSelect__tile {
  outline: none;
  cursor: pointer;
}


// isInvalid
.isInvalid {
  .Field {
    border-bottom-color: $red;
  }
}
</style>


<!-- Global Themes -->
<style lang="scss">
@import '@/styles/_variables';

// Theme Default
.InputSelect-theme-default > .InputSelect__tile {
  margin-top: 8px;
  &:focus .__tile {
    border-color: $dark;
  }
}
.InputSelect-theme-default .__tile {
  padding: 16px 0;
  text-align: center;
  font-weight: $font-weight-semi-bold;
  border: $border;
  border-radius: $radius;
  &.isSelected {
    // color: $white;
    background: rgba($light-blue, 0.2);
    border-color: $light-blue !important;
  }
}
@include bp-iPhonePlus {
  .InputSelect-theme-default {
    display: flex;
    flex-wrap: wrap;
  }
  .InputSelect-theme-default > .InputSelect__tile {
    flex: 1 1 50%;
    &:nth-child(odd) {
      padding-right: 4px;
    }
    &:nth-child(even) {
      padding-left: 4px;
    }
  }
}


// Theme Icon
.InputSelect-theme-icon > .InputSelect__tile {
  margin-top: 8px;
  &:focus .__tile {
    border-color: $dark;
  }
}
.InputSelect-theme-icon .__tile {
  display: flex;
  padding: 14px;
  align-items: flex-start;
  text-align: left;
  border: $border;
  border-radius: $radius;
  .__icon {
    flex: 0 0 32px;
  }
  .__label {
    flex: 1 1 100%;
    padding-left: 12px;
    line-height: 20px;
  }
  &.isSelected {
    background: rgba($light-blue, 0.2);
    border-color: $light-blue !important;
  }
}


// Theme Radio
.InputSelect-theme-radio > .InputSelect__tile {
  margin-top: 8px;
  &:focus .__tile {
    border-color: $dark;
  }
}
.InputSelect-theme-radio .__tile {
  display: flex;
  padding: 14px;
  align-items: flex-start;
  text-align: left;
  border: $border;
  border-radius: $radius;
  .__radio {
    flex: 0 0 24px;
    background-color: $white;
  }
  .__label {
    flex: 1 1 100%;
    padding-left: 12px;
    line-height: 22px;
  }
  &.isSelected {
    background: rgba($light-blue, 0.2);
    border-color: $light-blue !important;
  }
}
</style>
